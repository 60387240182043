<template>
    <div class="scrollbox" @scroll="scrollAdd" ref="scrollbox" @touchend="touchEnd" @touchstart="touchStart">
        <slot></slot>
        <div v-if="doneLoad" class="bottom-tips">{{finishedText}}~</div>
        <van-loading class="loading" v-if="loading" type="spinner" size="36px" color="#529100" />
    </div>
</template>
<script>
export default {
    props: {
       doneLoad: {
           type: Boolean,
           default: false
       },
       'finished-text': {
         type: String,
         default: '没有过更多了'
       },
       loading: {
         type: Boolean,
         default: false
       }
    },
    data() {
        return {
            startY: 0,
            endY: 0
        };
    },
    methods: {
        touchStart(e) {
            this.startY = e.changedTouches[0].clientY;
        },
        touchEnd(e) {
            this.endY = e.changedTouches[0].clientY;
            if (this.endY < this.startY) {
                 this.scrollAdd();
            }
        },
        scrollAdd() {
            let dom = this.$refs.scrollbox;
            if (dom.scrollHeight - dom.scrollTop <= dom.clientHeight) {
                if (this.doneLoad) return false;
                this.$emit('load');
            }
        }
    }
};
</script>

<style scoped>
.scrollbox {
    width: 100%;
    height: 100%;
    padding:24px 0 50px 0;
    box-sizing: border-box;
    overflow: auto;

}
.bottom-tips{
    width: 100%;
    text-align: center;
    padding: 15px 0;
}
.loading{
    text-align: center;
}
</style>
