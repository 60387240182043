<template>
  <div class="record">
    <scrollboxVue @load="scroll">
      <div class="header">
        <van-tabs v-model:active="active" line-width="24" color="#85C226">
          <van-tab title="已处理"></van-tab>
          <van-tab title="未处理"></van-tab>
        </van-tabs>
      </div>
      <template v-for="(item, index) of datalist" :key="index">
        <div class="box">
          <div class="item">
            <div class="device">
              <div class="device__name">报修设备</div>
              <div class="device__detail" @click="handleDetail(item)">
                <span>查看详情</span>
                <img src="@/assets/image/fixright.svg" />
              </div>
            </div>
            <div class="report" :class="active == 0 ? 'show-line-no' : 'show-line'">
              <div class="report__item">
                <img src="@/assets/image/shop.svg" alt="摊位" />
                <span class="report__item-title">摊位:</span>
                <span class="report__item-content">{{ item.address }}</span>
              </div>
              <div class="report__item">
                <img src="@/assets/image/date.svg" alt="" />
                <span class="report__item-title">提交时间:</span>
                <span class="report__item-content">{{ item.create_at }}</span>
              </div>
            </div>
            <div v-show="active == 1" class="check">
              <span v-show="item.repair_status == 0" class="check—none">待接单</span>
              <div class="check-done" v-show="item.repair_status != 0">
                <span>维修负责人:</span>
                <div class="check-user">{{ item.worker_name }} {{ item.worker_mobile }}</div>
                <img
                  src="@/assets/image/phone1.png"
                  alt="呼叫"
                  @click="common.callPhone(item.worker_mobile)"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <emptyItem v-if="datalist.length == 0 && !loading"></emptyItem>
    </scrollboxVue>
  </div>
</template>
<script setup>
//状态  0带受理  1派单 2已处理 3拒绝
import { ref, inject, watch, onMounted } from 'vue';
import { Toast } from 'vant';
import scrollboxVue from '@/unit/components/scrollbox.vue';
import * as common from '@/plugin/public';
const localdata = window.localStorage.getItem('propertyUserInfo')
  ? JSON.parse(window.localStorage.getItem('propertyUserInfo'))
  : {};
const $require = inject('$require');
const { router } = inject('ctx');
const datalist = ref([]);
const total = ref(0);
const active = ref(0);
const loading = ref(false);
const isScroll = ref(false);
const form = ref({
  pageNum: 1,
  pageSize: 10
});

const getList = () => {
  const params = {
    ...form.value,
    repair_status: active.value == 0 ? '-2' : '-1',
    people_type: 10,
    merchants_id: localdata.merchantsId
  };
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    duration: 5000
  });
  loading.value = true;
  $require.HttpPost('/h5/repair/list', params).then((res) => {
    total.value = res?.data?.total ?? 0;
    if (isScroll.value) {
      datalist.value = datalist.value.concat(res.data?.list ?? []);
    } else {
      datalist.value = res.data?.list ?? [];
    }
    loading.value = false;
    Toast.clear();
  });
};
onMounted(() => {
  getList();
});
watch(
  () => active.value,
  () => {
    datalist.value = [];
    isScroll.value = false;
    form.value.pageNum = 1;
    getList();
  }
);

function scroll() {
  if (total.value <= datalist.value.length) return;
  isScroll.value = true;
  form.value.pageNum++;
  getList();
}
const handleDetail = (item) => {
  router.push(`/repair/detail?id=${item.repair_id}`);
};
</script>
<style lang="scss" scoped>
.record {
  background-color: #f9f9f9;
  height: 100vh;
  padding-bottom: 40px;
  &::v-deep {
    .scrollbox {
      padding: 0;
    }
  }
  .box {
    box-sizing: border-box;
    padding: 20px 32px 0 32px;

    .item {
      padding: 0 24px;
      background-color: #fff;
      font-size: 28px;
      border-radius: 10px;
      .device {
        height: 75px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #efefef;
        &__name {
          color: #85c226;
        }
        &__detail {
          color: rgba(#000000, 0.3);
          font-size: 24px;
          img {
            width: 24px;
          }
        }
      }
      .report {
        height: 145px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        &__item {
          &-title {
            color: rgba(0, 0, 0, 0.6);
          }
          &-content {
            color: rgba(0, 0, 0, 1);
          }
          img {
            width: 32px;
            margin-right: 16px;
          }
        }
      }
      .show-line-no {
        border-bottom: none;
      }
      .show-line {
        border-bottom: 1px solid #efefef;
      }
      .check {
        height: 85px;
        display: flex;
        justify-content: center;
        align-items: center;

        .check—none {
          color: #fd6336;
          font-size: 28px;
        }
        .check-done {
          display: flex;
          align-items: center;
          .check-user {
            margin: 0 24px;
            color: #000000;
          }
          img {
            width: 64px;
          }
        }
      }
    }
  }
}
</style>
